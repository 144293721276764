export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une séance"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analyse"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dénivelé positif"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vitesse moyenne"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vitesse moy."])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["revenir à la séance"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dénivelé négatif"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afficher les filtres"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier la séance"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitude"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fin"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à partir de"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichier .gpx"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masquer les filtres"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les 2 valeurs pour l'élévation doivent être renseignées et être supérieures ou égales à 0."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La distance doit être supérieure à 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La durée doit être supérieure à 0 secondes"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séances récentes"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Charger les séances suivantes"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitude max"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fichiers max. "])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taille max. "])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vitesse max"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["altitude min"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment suivant"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance suivante"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["données issues du fichier gpx, sans correction"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de fichier fourni"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pas de répertoire"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de carte"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de segment suivant"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de séance suivante"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de notes"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de segment précédent"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de séance précédente"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de records."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas de séances."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pauses"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Segment précédent"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Séance précédente"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["record"]), _normalize(["records"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse moy."])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance la + longue"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dénivelé positif le + élevé"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durée la + longue"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vitesse max."])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nombre de caractères restants "])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segment"]), _normalize(["segments"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vitesse"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sports"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["début"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Départ et arrivée"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["démarrer l'axe de l'altitude à 0"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["titre"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jusqu'au"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["durée totale"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez votre première séance !"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ensoleillé"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuit claire"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuageux"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["brouillard"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partiellement nuageux"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuit partiellement nuageuse"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pluie"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neige fondue"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neige"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["venteux"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["humidité"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["température"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vent"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENE"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESE"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNE"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNO"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSE"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SO"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONO"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSO"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sans fichier .gpx"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["avec un fichier .gpx"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["séance"]), _normalize(["séances"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date de la séance"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Etes-vous sûr de vouloir supprimer cette séance ?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["archive .zip"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou une archive .zip contenant des fichiers .gpx"])}
}