export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Add a workout"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["analysis"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascent"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["average speed"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ave. speed"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["back to workout"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["date"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descent"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["display filters"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["distance"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["duration"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edit the workout"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["elevation"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["end"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["from"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".gpx file"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hide filters"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Both elevation values must be provided and be greater than or equal to 0."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The distance must be greater than 0"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The duration must be greater than 0 seconds"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Latest workouts"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Load more workouts"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. altitude"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max files"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max size"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. speed"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["min. altitude"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No next segment"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Next workout"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["notes"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["data from gpx, without any cleaning"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file provided"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no folder inside"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No map"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No next segment"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No next workout"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No notes"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No previous segment"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No previous workout"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No records."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No workouts."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pauses"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous segment"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previous workout"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["record"]), _normalize(["records"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ave. speed"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farthest distance"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Highest ascent"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longest duration"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. speed"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["remaining characters"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["segment"]), _normalize(["segments"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["speed"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["sport"]), _normalize(["sports"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start and finish"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start elevation axis at zero"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["title"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["to"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["total duration"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Upload one!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear day"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["clear night"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cloudy"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fog"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partly cloudy day"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["partly cloudy night"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rain"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sleet"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["snow"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wind"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["humidity"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["temperature"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wind"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ENE"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESE"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NE"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNE"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNW"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NW"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SE"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSE"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSW"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SW"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNW"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WSW"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["without .gpx file"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["with .gpx file"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["workout"]), _normalize(["workouts"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["workout date"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Are you sure you want to delete this workout?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".zip file"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["or .zip file containing .gpx files"])}
}