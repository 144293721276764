export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du keine Anweisungen erhalten?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfe deine E-Mail. Eine neue Bestätigungs-E-Mail wurde an die angegebene Adresse geschickt."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hast du bereits ein Konto?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchtest du dein Konto wirklich löschen? Alle Daten werden gelöscht. Dieser Vorgang kann nicht rückgängig gemacht werden."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelles Passwort"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebe eine gültige E-Mail-Adresse an."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebe ein Passwort ein"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenexport"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archiv herunterladen"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["generiere Link..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst alle 24 Stunden ein Archiv anfragen"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fehlgeschlagen (bitte frage einen anderen Export an)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["in Arbeit..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nach Benutzernamen filtern"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort verbergen"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungültiges Token, bitte fordere ein neues Passworts an."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ich möchte meinen Account löschen"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Die Datenschutzrichtlinie wurde aktualisiert, bitte ", _interpolate(_list(0)), " sie vor dem Fortfahren."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmeldung"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abmelden"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmelden"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Passwort"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Nutzer gefunden."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens 8 Zeichen sind erforderlich."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfe Deine E-Mail. Wenn Deine Adresse in der Datenbank enthalten ist, wirst du eine E-Mail mit einem Link erhalten um Dein Passwort zurückzusetzen."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mittel"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gut"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwortstärke"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["start"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibe einige, aber nicht alle Buchstaben groß."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge weitere weniger gebräuchliche Wörter hinzu."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide Jahreszahlen, die mit Dir in Verbindung gebracht werden."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schreibe mehr als nur den ersten Buchstaben groß."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide Daten und Jahreszahlen, die mit Dir in Verbindung gebracht werden."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide vorhersehbare Buchstabenersetzungen wie ", "@", " für a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwende längere Tastaturmuster und ändere mehrmals die Schreibrichtung."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Du kannst sichere Passwörter erstellen, ohne Symbole, Zahlen oder Großbuchstaben zu verwenden."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dieses Passwort auch anderweitig verwenden, sollten Sie es ändern."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide Angabe von letzten Jahreszahlen."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide wiederholungen von Wörtern und Zeichen."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide umgekehrte Schreibweisen gebräuchlicher Wörter."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vermeide gebräuchliche Zeichenfolgen."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwenden Sie mehrere Wörter, aber vermeide gebräuchliche Ausdrücke."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["schwach"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Dein Passwort wurde aktualisiert. Klicke ", _interpolate(_list(0)), " um dich anzumelden."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontoausgabe"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstiegs-bezogene Daten (Aufnahmen, gesamt)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zum Profil"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Biographie"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geburtsdatum"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datumsanzeigeformat"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil bearbeiten"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen ändern"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen für Sportarten ändern"])},
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Bitte ", _interpolate(_list(0)), " um Deine E-Mail Adresse nochmals zu ändern oder kontaktiere den Administrator"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erster Tag der Woche"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachname"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ort"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montag"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bildausgabe"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild entfernen"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild aktualisieren"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungsausgabe"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil-Ausgabe"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrierungsdatum"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktion"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Farbe"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vom Admin deaktiviert"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aktiv"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwindigkeitsschwellenwert für Stopp"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten-Einstellungsausgabe"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto wurde erfolgreich aktualisiert. Bitte prüfe Deine E-Mail um die neue E-Mail Adresse zu bestätigen."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto wurde erfolgreich erstellt."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein Aktivierungslink für Dein Konto wurde an die angegebene E-Mail Adresse geschickt."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dein Konto wurde erfolgreich aktualisiert."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sontag"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konto"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anwendungen"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bild"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutzrichtlinie"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profil"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sportarten"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zeitzone"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Imperiales System (ft, mi, mph, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einheiten für die Distanz"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Metrisches System (m, km, m/s, °C)"])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Ich habe die ", _interpolate(_list(0)), " gelesen und stimme ihr zu."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registrieren"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entschuldigung, die Registrierung ist deaktiviert."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail zur Kontobestätigung erneut senden"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort zurücksetzen"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["überprüfen"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort anzeigen"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Account ist inaktiv."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzername"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 bis 30 Zeichen sind erforderlich, nur alphanumerische Zeichen und der Unterstrich _ sind erlaubt."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerbild"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Du hast die ", _interpolate(_list(0)), " akzeptiert."])}
}