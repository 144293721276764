export default {
  "ERROR": {
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Network Error."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error. Please try again or contact the administrator."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["At least one file in zip archive exceeds size limit, please check the archive."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A completed export request already exists."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: valid email must be provided."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during gpx file parsing."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error during gpx processing."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error on getting configuration."])},
    "error when saving workout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when saving workout."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error when updating configuration"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, please try again or contact the administrator."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Error, registration is disabled."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File extension not allowed."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["File size is greater than the allowed size."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid credentials."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provided data are invalid."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token, please log in again."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Invalid token, please log in again."])},
    "new email must be different than curent email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The new email must be different than curent email"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No file provided."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["No selected file."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A data export request already exists."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password: password and password confirmation don't match."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Provide a valid auth token."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signature expired. Please log in again."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, that username is already taken."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sport does not exist."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Successfully registered."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The number of files in the archive exceeds the limit."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["User does not exist."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A valid email must be provided for administrator contact"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You can not delete your account, no other user has admin rights."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["You do not have permissions."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["next"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["previous"])}
  }
}