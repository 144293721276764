export default {
  "ABOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["à propos"])},
  "CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmation"])},
  "CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["contact"])},
  "DAY": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["jour"]), _normalize(["jours"])])},
  "DISPLAYED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Affiché"])},
  "DOCUMENTATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["documentation (en)"])},
  "HERE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ici"])},
  "HIDDEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Masqué"])},
  "HOME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accueil"])},
  "SELECTS": {
    "ORDER": {
      "ASC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ascendant"])},
      "DESC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["descendant"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tri"])}
    },
    "ORDER_BY": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trier par "])}
    },
    "PER_PAGE": {
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["par page"])}
    }
  },
  "TOTAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])}
}