export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n'avez pas reçu les instructions ?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez vos courriels. Un nouveau courriel de confirmation a été envoyé à l'adresse électronique fournie."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Êtes-vous sûr·e de vouloir supprimer votre compte ? Toutes les données seront définitivement effacées."])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe actuel"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Courriel"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez une adresse électronique valide."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saisissez un mot de passe"])},
  "EXPORT_REQUEST": {
    "DATA_EXPORT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export des données"])},
    "DOWNLOAD_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Télécharger l'archive"])},
    "GENERATING_LINK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lien en cours de génération..."])},
    "ONLY_ONE_EXPORT_PER_DAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez demander un export par 24h"])},
    "STATUS": {
      "errored": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en erreur (veuillez demander une nouvelle archive)"])},
      "in_progress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["en cours..."])}
    }
  },
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtrer sur le nom d'utilisateur"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["masquer le mot de passe"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeton invalide, veuillez demander une nouvelle réinitialisation de mot de passe."])},
  "I_WANT_TO_DELETE_MY_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Je souhaite supprimer mon compte"])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
  "LAST_PRIVACY_POLICY_TO_VALIDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La politique de confidentialité a été mise à jour. Veuillez l'", _interpolate(_list(0)), " avant de poursuivre."])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se connecter"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Se déconnecter"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["connecter"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun utilisateur trouvé."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié ?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["8 caractères minimum."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialisation du mot de passe"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vérifiez vos courriels. Si vote adresse est dans notre base de données, vous recevrez un courriel avec un lien pour réinitialiser votre mot de passe."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["moyenne"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bonne"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["robustesse du mot de passe"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forte"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettez quelques lettres en majuscules, mais pas toutes."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajoutez des mots moins courants."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les années qui vous sont associées. (ex : date de naissance)."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Capitalisez mais pas seulement la première lettre."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les dates et les années qui vous sont associées. (ex : date ou année de naissance)."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les substitutions de lettres prévisibles comme ", "@", " pour a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez des motifs de clavier plus longs et changez de sens de frappe plusieurs fois."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous pouvez créer des mots de passe forts sans utiliser de symboles, de chiffres ou de lettres majuscules."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Si vous utilisez ce mot de passe ailleurs, vous devriez le modifier."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les dernières années."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les mots et les caractères répétés."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les orthographes inversées des mots courants."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Évitez les séquences de caractères courantes."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez plusieurs mots, mais évitez les phrases courantes."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["faible"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Votre mot de passe a été mis à jour. Cliquez ", _interpolate(_list(0)), " pour vous connecter."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du compte"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Données relatives au dénivelé positif (records, total)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Revenir au profil"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date de naissance"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format d'affichage de la date"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier le profil"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les préférences"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifier les préférences des sports"])},
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Veuillez vous ", _interpolate(_list(0)), " pour changer de nouveau votre adresse électronique ou contacter l'administrateur"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Premier jour de la semaine"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prénom"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Langue"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lundi"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Image de profil"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour de l'image de profil"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Supprimer"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour l'image"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des préférences"])},
    "PRIVACY-POLICY_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour du profil"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date d'inscription"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["action"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["couleur"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["désactivé par l'administrateur"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["actif"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["label"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["seuil de vitesse arrêtée"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mise à jour des préférences des sports"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été modifié avec succès. Veuillez vérifier votre boîte de réception pour valider votre nouvelle adresse électronique."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été créé avec succès."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un lien pour activer votre compte a été envoyé à l'adresse électronique fournie."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre compte a été modifié avec succès."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dimanche"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["compte"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apps"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["image"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["préférences"])},
      "PRIVACY-POLICY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["politique de confidentialité"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profil"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sports"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fuseau horaire"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système impérial (ft, mi, mph, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unités pour les distances"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Système métrique (m, km, m/s, °C)"])}
    }
  },
  "READ_AND_ACCEPT_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["J'ai lu et accepte la ", _interpolate(_list(0)), "."])},
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S'inscrire"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Désolé, les inscriptions sont désactivées."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer à nouveau le courriel de confirmation de compte"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Réinitialiser votre mot de passe"])},
  "REVIEW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["accepter"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["afficher le mot de passe"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le compte de cet utilisateur est inactif."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom d'utilisateur"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["3 à 30 caractères requis, seuls les caractères alphanumériques et le caractère _ sont autorisés."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["photo de l'utilisateur"])},
  "YOU_HAVE_ACCEPTED_PRIVACY_POLICY": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Vous avez accepté la ", _interpolate(_list(0)), "."])}
}