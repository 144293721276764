export default {
  "ABOUT": {
    "DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extra informatie die nuttig kan zijn voor uw gebruikers. Markdown opmaak kan gebruikt worden."])},
    "TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gedetailleerde informatie over deze instantie"])}
  },
  "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Actie"])},
  "ACTIVATE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Activeer account"])},
  "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer"])},
  "ADMINISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Instellingen"])},
  "ADMIN_RIGHTS_DELETE_USER_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toevoegen/verwijderen van administrator rechten, verwijder gebruikersaccount."])},
  "APPLICATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatie"])},
  "APP_CONFIG": {
    "ADMIN_CONTACT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Administrator email voor contact"])},
    "MAX_FILES_IN_ZIP_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. aantal bestanden in zip archief"])},
    "MAX_USERS_HELP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bij 0, geen limiet op registratie."])},
    "MAX_USERS_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. aantal actieve gebruikers"])},
    "NO_CONTACT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["geen contact email"])},
    "SINGLE_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. grootte van bestanden voor upload (in Mb)"])},
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applicatie configureren"])},
    "ZIP_UPLOAD_MAX_SIZE_LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. grootte van zip archief (in Mb)"])}
  },
  "BACK_TO_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Terug naar beheer"])},
  "CONFIRM_USER_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Weet u zeker dat u de account ", _interpolate(_list(0)), " wilt wissen? Alle gegevens worden verwijderd, dit kan niet ongedaan gemaakt worden."])},
  "CONFIRM_USER_PASSWORD_RESET": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Weet u zeker dat u het wachtwoord voor ", _interpolate(_list(0)), " wilt resetten?"])},
  "CURRENT_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Huidige email"])},
  "DELETE_USER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder gebruiker"])},
  "EMAIL_SENDING_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Emails verzenden is uitgeschakeld."])},
  "ENABLE_DISABLE_SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aan- of uitzetten van sporten."])},
  "NEW_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nieuwe email"])},
  "NO_TEXT_ENTERED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen tekst ingegeven"])},
  "PASSWORD_RESET_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het wachtwoord werd gereset."])},
  "PRIVACY_POLICY_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voeg uw eigen privacybeleid toe, of laat leeg om het standaard beleid te gebruiken. Markdown opmaak kan gebruikt worden."])},
  "REGISTRATION_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie is momenteel uitgeschakeld."])},
  "REGISTRATION_ENABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie is momenteel ingeschakeld."])},
  "RESET_USER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset wachtwoord"])},
  "SPORTS": {
    "TABLE": {
      "ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingeschakeld"])},
      "HAS_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingen aanwezig"])},
      "IMAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Afbeelding"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label"])}
    },
    "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beheer sporten"])}
  },
  "UPDATE_APPLICATION_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De applicatie configureren."])},
  "UPDATE_USER_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Update email"])},
  "USER": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["gebruiker"]), _normalize(["gebruikers"])])},
  "USERS": {
    "SELECTS": {
      "ORDER_BY": {
        "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["admin status"])},
        "CREATED_AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registratie datum"])},
        "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account status"])},
        "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gebruikersnaam"])},
        "WORKOUTS_COUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aantal trainingen"])}
      }
    },
    "TABLE": {
      "ADD_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin rechten toevoegen"])},
      "REMOVE_ADMIN_RIGHTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verwijder admin rechten"])}
    }
  },
  "USER_EMAIL_UPDATE_SUCCESSFUL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het emailadres werd bijgewerkt."])}
}