export default {
  "ACCOUNT_CONFIRMATION_NOT_RECEIVED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Non hai ricevuto istruzioni?"])},
  "ACCOUNT_CONFIRMATION_SENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la tua email. Una nuova email di conferma è stata inviata all'indirizzo specificato."])},
  "ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Admin"])},
  "ALREADY_HAVE_ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hai già un account?"])},
  "CONFIRM_ACCOUNT_DELETION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sei sicuro di voler eliminare il tuo account? Tutti i dati saranno eliminati, quest'azione non può essere annullata"])},
  "CURRENT_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password corrente"])},
  "EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email"])},
  "EMAIL_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci un indirizzo email valido."])},
  "ENTER_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci una password"])},
  "FILTER_ON_USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Filtra per username"])},
  "HIDE_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nascondi password"])},
  "INVALID_TOKEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Token invalido, per favore richiedi un nuovo reset della password."])},
  "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
  "LOGIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
  "LOGOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logout"])},
  "LOG_IN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["log in"])},
  "NEW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nuova password"])},
  "NO_USERS_FOUND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nessun utente trovato."])},
  "PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password"])},
  "PASSWORD_FORGOTTEN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Password dimenticata?"])},
  "PASSWORD_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci almeno 8 caratteri."])},
  "PASSWORD_RESET": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Reset password"])},
  "PASSWORD_SENT_EMAIL_TEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Controlla la tua email. Se l'indirizzo inserito è nel nostro database, riceverai un'email con un link per resettare la tua password."])},
  "PASSWORD_STRENGTH": {
    "AVERAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["media"])},
    "GOOD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["buona"])},
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sicurezza password"])},
    "STRONG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["forte"])},
    "SUGGESTIONS": {
      "allUppercase": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci qualche maiuscola."])},
      "anotherWord": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiungi più parole che sono meno comuni."])},
      "associatedYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita date che possono essere associate a te."])},
      "capitalization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inserisci maiuscole oltre alla prima lettera."])},
      "dates": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita date che possono essere associate a te."])},
      "l33t": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita sostituzioni prevedibili, per esempio, ", "@", " per la a."])},
      "longerKeyboardPattern": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa patterns più lunghi e cambia direzione di scrittura più volte."])},
      "noNeed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Puoi creare password sicure senza utilizzare simboli, numeri, o maiuscole."])},
      "pwned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["È consigliabile cambiare questa password se è utilizzata per altro."])},
      "recentYears": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita date recenti."])},
      "repeated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita di ripetere lettere e parole."])},
      "reverseWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita di scrivere parole al contrario."])},
      "sequences": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evita sequenze di caratteri comuni."])},
      "useWords": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Usa più parole, ma evita frasi comuni."])}
    },
    "WEAK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bassa"])}
  },
  "PASSWORD_UPDATED": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["La tua password è stata aggiornata. Clicca ", _interpolate(_list(0)), " per effettuare il login."])},
  "PROFILE": {
    "ACCOUNT_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tipo di account"])},
    "ASCENT_DATA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dati relativi alle salite (singoli, totale)"])},
    "BACK_TO_PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ritorna al profilo"])},
    "BIO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bio"])},
    "BIRTH_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di nascita"])},
    "DATE_FORMAT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Formato data"])},
    "EDIT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica profilo"])},
    "EDIT_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica preferenze"])},
    "EDIT_SPORTS_PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modifica preferenze sport"])},
    "ERRORED_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, list: _list } = ctx;return _normalize(["Per favore ", _interpolate(_list(0)), " per cambiare di nuovo la tua mail o contatta l'amministratore"])},
    "FIRST_DAY_OF_WEEK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Primo giorno della settimana"])},
    "FIRST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nome"])},
    "LANGUAGE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lingua"])},
    "LAST_NAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cognome"])},
    "LOCATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Posizione"])},
    "MONDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunedì"])},
    "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "PICTURE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Foto"])},
    "PICTURE_REMOVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rimuovi foto"])},
    "PICTURE_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aggiorna foto"])},
    "PREFERENCES_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferenze"])},
    "PROFILE_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Profilo"])},
    "REGISTRATION_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Data di registrazione"])},
    "SPORT": {
      "ACTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["azione"])},
      "COLOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["colore"])},
      "DISABLED_BY_ADMIN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["disattivato dall'amministratore"])},
      "IS_ACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["attivo"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["etichetta"])},
      "STOPPED_SPEED_THRESHOLD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Limite minimo di velocità"])}
    },
    "SPORTS_EDITION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Preferenze sport"])},
    "SUCCESSFUL_EMAIL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato aggiornato con successo. Per favore controlla la tua email per confermare il tuo indirizzo email."])},
    "SUCCESSFUL_REGISTRATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato creato con successo."])},
    "SUCCESSFUL_REGISTRATION_WITH_EMAIL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Un link per attivare il tuo account è stato inviato all'indirizzo specificato."])},
    "SUCCESSFUL_UPDATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il tuo account è stato aggiornato con successo."])},
    "SUNDAY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenica"])},
    "TABS": {
      "ACCOUNT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["account"])},
      "APPS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["apps"])},
      "PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foto"])},
      "PREFERENCES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["preferenze"])},
      "PROFILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["profilo"])},
      "SPORTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sports"])}
    },
    "TIMEZONE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Timezone"])},
    "UNITS": {
      "IMPERIAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema imperiale (ft, mi, mph, °F)"])},
      "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unità per la distanza"])},
      "METRIC": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sistema metrico (m, km, m/s, °C)"])}
    }
  },
  "REGISTER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registra"])},
  "REGISTER_DISABLED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mi dispiace, la registrazione è disabilitata."])},
  "RESENT_ACCOUNT_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Re-invia email di conferma dell'account"])},
  "RESET_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Resetta password"])},
  "SHOW_PASSWORD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mostra password"])},
  "THIS_USER_ACCOUNT_IS_INACTIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questo account è inattivo."])},
  "USERNAME": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Username"])},
  "USERNAME_INFO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sono richiesti da 3 a 30 caratteri, è permesso utilizzare solo caratteri alfanumerici ed il simbolo \"_\"."])},
  "USER_PICTURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["foto profilo"])}
}