import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-d5e9b348"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "start-chart" }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = { class: "chart-radio" }
const _hoisted_5 = ["checked"]
const _hoisted_6 = ["checked"]
const _hoisted_7 = ["checked"]
const _hoisted_8 = { key: 0 }
const _hoisted_9 = ["checked"]
const _hoisted_10 = { key: 1 }
const _hoisted_11 = ["checked"]
const _hoisted_12 = { key: 2 }
const _hoisted_13 = ["checked"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Chart = _resolveComponent("Chart")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.hideChartIfNoData && _ctx.emptyStats)
      ? (_openBlock(), _createElementBlock("div", _hoisted_2, _toDisplayString(_ctx.$t('workouts.NO_WORKOUTS')), 1))
      : (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            _createElementVNode("label", null, [
              _createElementVNode("input", {
                type: "radio",
                name: "total_distance",
                checked: _ctx.displayedData === 'total_distance',
                onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
              }, null, 8, _hoisted_5),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.DISTANCE')), 1)
            ]),
            _createElementVNode("label", null, [
              _createElementVNode("input", {
                type: "radio",
                name: "total_duration",
                checked: _ctx.displayedData === 'total_duration',
                onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
              }, null, 8, _hoisted_6),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.DURATION')), 1)
            ]),
            _createElementVNode("label", null, [
              _createElementVNode("input", {
                type: "radio",
                name: "nb_workouts",
                checked: _ctx.displayedData === 'nb_workouts',
                onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
              }, null, 8, _hoisted_7),
              _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.WORKOUT', 2)), 1)
            ]),
            (_ctx.fullStats)
              ? (_openBlock(), _createElementBlock("label", _hoisted_8, [
                  _createElementVNode("input", {
                    type: "radio",
                    name: "average_speed",
                    checked: _ctx.displayedData === 'average_speed',
                    onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
                  }, null, 8, _hoisted_9),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.AVERAGE_SPEED')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.fullStats)
              ? (_openBlock(), _createElementBlock("label", _hoisted_10, [
                  _createElementVNode("input", {
                    type: "radio",
                    name: "total_ascent",
                    checked: _ctx.displayedData === 'total_ascent',
                    onClick: _cache[4] || (_cache[4] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
                  }, null, 8, _hoisted_11),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.ASCENT')), 1)
                ]))
              : _createCommentVNode("", true),
            (_ctx.fullStats)
              ? (_openBlock(), _createElementBlock("label", _hoisted_12, [
                  _createElementVNode("input", {
                    type: "radio",
                    name: "total_descent",
                    checked: _ctx.displayedData === 'total_descent',
                    onClick: _cache[5] || (_cache[5] = 
//@ts-ignore
(...args) => (_ctx.updateDisplayData && _ctx.updateDisplayData(...args)))
                  }, null, 8, _hoisted_13),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('workouts.DESCENT')), 1)
                ]))
              : _createCommentVNode("", true)
          ]),
          (_ctx.labels.length > 0)
            ? (_openBlock(), _createBlock(_component_Chart, {
                key: 0,
                datasets: _ctx.datasets,
                labels: _ctx.labels,
                displayedData: _ctx.displayedData,
                displayedSportIds: _ctx.displayedSportIds,
                fullStats: _ctx.fullStats,
                useImperialUnits: _ctx.user.imperial_units
              }, null, 8, ["datasets", "labels", "displayedData", "displayedSportIds", "fullStats", "useImperialUnits"]))
            : _createCommentVNode("", true)
        ]))
  ]))
}