export default {
  "ADD_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training hinzufügen"])},
  "ANALYSIS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse"])},
  "ASCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aufstieg"])},
  "AVERAGE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittsgeschwindigkeit"])},
  "AVE_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschn. Geschwindigkeit"])},
  "BACK_TO_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück zum Training"])},
  "DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum"])},
  "DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abstieg"])},
  "DISPLAY_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zeige Filter"])},
  "DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entfernung"])},
  "DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dauer"])},
  "EDIT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Training bearbeiten"])},
  "ELEVATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhe"])},
  "END": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ende"])},
  "FROM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Von"])},
  "GPX_FILE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".gpx Datei"])},
  "HIDE_FILTERS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verberge Filter"])},
  "INVALID_ASCENT_OR_DESCENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beide Höhenwerte müssen angegeben werden und größer oder gleich 0 sein."])},
  "INVALID_DISTANCE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Distanz muss größer als 0 sein"])},
  "INVALID_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Dauer muss größer als 0 Sekunden sein"])},
  "LATEST_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Trainings"])},
  "LOAD_MORE_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade mehr Trainings"])},
  "MAX_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maximale Höhe"])},
  "MAX_FILES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale Dateianzahl"])},
  "MAX_SIZE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximalgröße"])},
  "MAX_SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Geschwindigkeit"])},
  "MIN_ALTITUDE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["minimale Höhe"])},
  "NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächstes Segment"])},
  "NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nächstes Training"])},
  "NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anmerkungen"])},
  "NO_DATA_CLEANING": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daten aus gpx, ohne Bereinigung"])},
  "NO_FILE_PROVIDED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Datei angegeben"])},
  "NO_FOLDER": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["enthält keinen Ordner"])},
  "NO_MAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Karte"])},
  "NO_NEXT_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein nächstes Segment"])},
  "NO_NEXT_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein nächstes Training"])},
  "NO_NOTES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Anmerkungen"])},
  "NO_PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein vorheriges Segment"])},
  "NO_PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kein vorheriges Training"])},
  "NO_RECORDS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Aufzeichnungen."])},
  "NO_WORKOUTS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Trainings."])},
  "PAUSES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pausen"])},
  "PREVIOUS_SEGMENT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriges Segment"])},
  "PREVIOUS_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorheriges Training"])},
  "RECORD": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Aufzeichnung"]), _normalize(["Aufzeichnungen"])])},
  "RECORD_AS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschn. Geschwindigkeit"])},
  "RECORD_FD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weiteste Entfernung"])},
  "RECORD_HA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höchster Anstieg"])},
  "RECORD_LD": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Längste Dauer"])},
  "RECORD_MS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. Geschwindigkeit"])},
  "REMAINING_CHARS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["verbleibende Zeichen"])},
  "SEGMENT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Segment"]), _normalize(["Segmente"])])},
  "SPEED": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geschwindigkeit"])},
  "SPORT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Sportart"]), _normalize(["Sportarten"])])},
  "START": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start"])},
  "START_AND_FINISH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Start und Ziel"])},
  "START_ELEVATION_AT_ZERO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Höhenachse bei Null starten"])},
  "TITLE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Titel"])},
  "TO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bis"])},
  "TOTAL_DURATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtdauer"])},
  "UPLOAD_FIRST_WORKOUT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Füge erstes Training hinzu!"])},
  "WEATHER": {
    "DARK_SKY": {
      "clear-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klarer Tag"])},
      "clear-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["klare Nacht"])},
      "cloudy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["wolkig"])},
      "fog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nebel"])},
      "partly-cloudy-day": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teilweise bewölkter Tag"])},
      "partly-cloudy-night": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teilweise bewölkte Nacht"])},
      "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regen"])},
      "sleet": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schneeregen"])},
      "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schnee"])},
      "wind": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind"])}
    },
    "HUMIDITY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luftfeuchtigkeit"])},
    "TEMPERATURE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temperatur"])},
    "WIND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wind"])},
    "WIND_DIRECTIONS": {
      "E": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["O"])},
      "ENE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ONO"])},
      "ESE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["OSO"])},
      "N": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["N"])},
      "NE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NO"])},
      "NNE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNO"])},
      "NNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NNW"])},
      "NW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NW"])},
      "S": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S"])},
      "SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SO"])},
      "SSE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSO"])},
      "SSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SSW"])},
      "SW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SW"])},
      "W": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["W"])},
      "WNW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WNW"])},
      "WSW": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WSW"])}
    }
  },
  "WITHOUT_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ohne .gpx Datei"])},
  "WITH_GPX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mit .gpx Datei"])},
  "WORKOUT": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Training"]), _normalize(["Trainings"])])},
  "WORKOUT_DATE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trainingsdatum"])},
  "WORKOUT_DELETION_CONFIRMATION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bist du sicher, dass du dieses Training löschen möchtest?"])},
  "ZIP_ARCHIVE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([".zip Datei"])},
  "ZIP_ARCHIVE_DESCRIPTION": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oder .zip Datei mit .gpx Dateien"])}
}