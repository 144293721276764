export default {
  "ERROR": {
    "Network Error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Netwerk fout."])},
    "UNKNOWN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout. Probeer opnieuw of contacteer de administrator."])},
    "at least one file in zip archive exceeds size limit, please check the archive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ten minste 1 bestand in het zip archief is groter dan toegelaten, gelieve de bestanden te controleren."])},
    "completed request already exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er bestaat al een voltooide export aanvraag."])},
    "email: valid email must be provided": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Email: een geldige email is vereist."])},
    "error during gpx file parsing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het verwerken van het gpx bestand."])},
    "error during gpx processing": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij verwerken van gpx."])},
    "error on getting configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij ophalen van configuratie."])},
    "error when updating configuration": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout bij het updaten van de configuratie"])},
    "error, please try again or contact the administrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout, probeer opnieuw of contacteer de administrator."])},
    "error, registration is disabled": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fout, registratie is uitgeschakeld."])},
    "file extension not allowed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsformaat niet toegelaten."])},
    "file size is greater than the allowed size": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestandsgrootte is groter dan toegestaan."])},
    "invalid credentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige inloggegevens."])},
    "invalid payload": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ingegeven data is ongeldig."])},
    "invalid token, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige token, log opnieuw in."])},
    "invalid token, please request a new token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ongeldige token, log opnieuw in."])},
    "new email must be different than curent email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het nieuwe email adres dient te verschillen van het oude"])},
    "no file part": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestand opgegeven."])},
    "no selected file": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geen bestand geselecteerd."])},
    "ongoing request exists": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Er bestaat al een export aanvraag."])},
    "password: password and password confirmation do not match": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord: het wachtwoord en de bevestiging komen niet overeen."])},
    "provide a valid auth token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geef een geldig auth token."])},
    "signature expired, please log in again": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Signatuur verlopen. Log opnieuw in."])},
    "sorry, that username is already taken": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, deze gebruikersnaam is al in gebruik."])},
    "sport does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deze sport bestaat niet."])},
    "successfully registered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Succesvol geregistreerd."])},
    "the number of files in the archive exceeds the limit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het aantal bestanden in het zip archief overschrijdt de limiet."])},
    "user does not exist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebruiker bestaat niet."])},
    "valid email must be provided for admin contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Een geldige email is vereist voor admin contact informatie"])},
    "you can not delete your account, no other user has admin rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U kunt uw account niet verwijderen, geen andere gebruiker heeft admin rechten."])},
    "you do not have permissions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["U hebt geen machtigingen."])}
  },
  "PAGINATION": {
    "NEXT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["volgende"])},
    "PREVIOUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vorige"])}
  }
}