export default {
  "Cycling (Sport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vélo (Sport)"])}
  },
  "Cycling (Transport)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vélo (Transport)"])}
  },
  "Cycling (Virtual)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vélo (Virtuel)"])}
  },
  "Hiking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Randonnée"])}
  },
  "Mountain Biking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VTT"])}
  },
  "Mountain Biking (Electric)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VTT (Électrique)"])}
  },
  "Mountaineering": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alpinisme"])}
  },
  "Rowing": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aviron"])}
  },
  "Running": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Course"])}
  },
  "Skiing (Alpine)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski (Alpin)"])}
  },
  "Skiing (Cross Country)": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ski (Randonnée)"])}
  },
  "Snowshoes": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raquettes"])}
  },
  "Trail": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trail"])}
  },
  "Walking": {
    "LABEL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marche"])}
  }
}